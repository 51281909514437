import { useEffect, useState } from 'react'

const isBrowser = typeof window !== 'undefined'

const hasExistingScript = (src: string): boolean => {
  const existingScript = document.querySelectorAll(`script[src="${src}"]`)
  return existingScript.length > 0
}

const useLoadScript = (
  src: string,
  { location }: { location: 'head' | 'body' } = { location: 'body' }
) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  const scriptSrc: string = src

  useEffect(() => {
    // check if the script already exists or not
    const hasExisting = hasExistingScript(scriptSrc)
    if (!isBrowser || hasExisting) {
      return
    }

    setLoading(true)

    const scriptElement: HTMLScriptElement = document.createElement('script')
    scriptElement.setAttribute('src', scriptSrc)
    scriptElement.setAttribute('type', 'text/javascript')

    const handleLoad = () => setLoading(false)
    const handleError = () => {
      setLoading(false)
      setError(true)
      scriptElement.remove()
    }

    scriptElement.addEventListener('load', handleLoad)
    scriptElement.addEventListener('error', handleError)

    if (location === 'body') {
      document.body.appendChild(scriptElement)
    } else if (location === 'head') {
      document.head.appendChild(scriptElement)
    }

    return () => {
      scriptElement.removeEventListener('load', handleLoad)
      scriptElement.removeEventListener('error', handleError)
    }
  }, [scriptSrc, location])

  return { loading, error }
}

export default useLoadScript
